exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-commercial-62-avenue-js": () => import("./../../../src/pages/commercial-62-avenue.js" /* webpackChunkName: "component---src-pages-commercial-62-avenue-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-management-js": () => import("./../../../src/pages/portfolio-management.js" /* webpackChunkName: "component---src-pages-portfolio-management-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-project-management-js": () => import("./../../../src/pages/project-management.js" /* webpackChunkName: "component---src-pages-project-management-js" */),
  "component---src-pages-residential-bhutani-belfair-sector-150-noida-js": () => import("./../../../src/pages/residential-bhutani-belfair-sector150-noida.js" /* webpackChunkName: "component---src-pages-residential-bhutani-belfair-sector-150-noida-js" */),
  "component---src-pages-terms-and-condition-js": () => import("./../../../src/pages/terms-and-condition.js" /* webpackChunkName: "component---src-pages-terms-and-condition-js" */)
}

